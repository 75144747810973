import * as React from 'react'
import { useGridApiContext } from '@mui/x-data-grid'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
// import { StyledInput } from './StyledComponents'

export default function EditAutocomplete ({ params, renderOptions }) {
  // const theme = useTheme()

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    height: '100%',
    [`& .${autocompleteClasses.inputRoot}`]: {
      ...theme.typography.body2,
      padding: '1px 0',
      height: '100%',
      '& input': {
        padding: '0 16px',
        height: '100%'
      }
    }
  }))

  const { id, value, field } = params

  const apiRef = useGridApiContext()

  const handleChange = React.useCallback(
    (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event)
    },
    [apiRef, field, id]
  )

  return (
    <StyledAutocomplete
      value={value}
      onChange={handleChange}
      options={renderOptions}
      getOptionLabel={(option) => option}
      autoHighlight
      fullWidth
      open
      disableClearable
      renderOption={(optionProps, option) => (
        <Box
          component="li"
          sx={{
            '& > img': {
              mr: 1.5,
              flexShrink: 0
            }
          }}
          {...optionProps}
        >
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <InputBase
          autoFocus
          fullWidth
          id={params.id}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
          {...params.InputProps}
        />
      )}
    />
  )
}

/* return (
    <Autocomplete
      sx={{
        height: '100%',
        [`& .${autocompleteClasses.inputRoot}`]: {
          // ...theme.typography.body2,
          padding: '1px 0',
          height: '100%',
          '& input': {
            padding: '0 16px',
            height: '100%'
          }
        }
      }}
      value={value}
      onChange={handleChange}
      options={renderOptions}
      getOptionLabel={(option) => option}
      autoHighlight
      fullWidth
      open
      disableClearable
      renderOption={(optionProps, option) => (
        <Box
          component="li"
          sx={{
            '& > img': {
              mr: 1.5,
              flexShrink: 0
            }
          }}
          {...optionProps}
        >
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <InputBase
          autoFocus
          fullWidth
          id={params.id}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
          {...params.InputProps}
        />
      )}
    />
  )
} */
EditAutocomplete.propTypes = {
  params: PropTypes.any,
  renderOptions: PropTypes.any

}
