import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

export function getSelectedBUListItem (index, bunit, isSelected, handleClickDeleteFunction, handleSelectBUClick) {
  return <ListItem key={bunit.id}
            sx={{ backgroundColor: '#F6F9F1' }}
            secondaryAction={
                <IconButton edge='end' onClick={(event) => handleClickDeleteFunction(event, index)}>
                    <DeleteIcon />
                </IconButton>
            }>
            <ListItemButton
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#F6F9F1'
                  }
                }}
                selected={isSelected}
                onClick={(event) => handleSelectBUClick(event, index)}>
                <ListItemText primary={bunit.name}/>
            </ListItemButton>
        </ListItem>
}

export function getBUListItem (index, bunit, isSelected, handleClickDeleteFunction, handleSelectBUClick) {
  return <ListItem key={bunit.id}
            secondaryAction={
                <IconButton edge='end' onClick={(event) => handleClickDeleteFunction(event, index)}>
                    <DeleteIcon />
                </IconButton>
            }>
            <ListItemButton
                selected={isSelected}
                onClick={(event) => handleSelectBUClick(event, index)}>
                <ListItemText primary={bunit.name}/>
            </ListItemButton>
        </ListItem>
}
