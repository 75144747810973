import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from '../Components/LMDataGrid'
import { getConnectPhoneNumbers, saveStoreConfig, getStoreConfig, deleteStoreConfig } from '../APIUtils/BackendHandler'

export default function PublicHolidays () {
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [phoneNumbers, setPhoneNumbers] = React.useState([''])

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'store_name',
      headerName: 'Store name',
      type: 'string',
      width: 250,
      editable: true
    },
    {
      field: 'connect_did',
      headerName: 'Connect Number',
      type: 'singleSelect',
      width: 200,
      valueOptions: phoneNumbers,
      editable: true
    },
    {
      field: 'store_did',
      headerName: 'Store Number',
      type: 'string',
      width: 200,
      editable: true
    },
    {
      field: 'store_id',
      headerName: 'Store id',
      type: 'string',
      width: 180,
      editable: true
    },
    {
      field: 'store_transfer_phonenumber',
      headerName: 'Store transfer number',
      type: 'string',
      width: 200,
      editable: true
    },
    {
      field: 'closed',
      headerName: 'Closed',
      editable: true,
      type: 'boolean'
    },
    {
      field: 'message',
      headerName: 'Message',
      type: 'string',
      flex: 100,
      editable: true
    }
  ]

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      store_name: 'Enter a store name',
      connect_did: '',
      store_did: '',
      store_id: '',
      store_transfer_phonenumber: '',
      message: '',
      closed: true
    }
    return item
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')
    const success = await saveStoreConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting item in main with row id->', id)

    const success = await deleteStoreConfig(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    }

    return success
  }

  useEffect(() => {
    const doPhoneNumberLookup = async () => {
      console.log('getting phone numbers')
      const nums = await getConnectPhoneNumbers()
      // console.log('nums->', nums)
      setPhoneNumbers(nums)
    }
    doPhoneNumberLookup()
  }, [])

  useEffect(() => {
    const doLoadData = async () => {
      const data = await getStoreConfig()
      setRows(data)
      setOpenBackdrop(false)
    }
    doLoadData()
  }, [])

  return (
    <div style={{ margin: '10px' }}>
        <CssBaseline/>
        <h1>Stores</h1>
        <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='store_name'
                    createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
                    setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage}
        />
    </div>
  )
}
