import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from './LMDataGrid'
import { getBackOfficeExtensions, getAgentUsernameList, saveBackofficeExtensionConfig, deleteBackOfficeExtensionConfigEntry } from '../APIUtils/BackendHandler'
import EditAutocomplete from '../Components/EditAutocomplete'

export default function BackOffice () {
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [agents, setAgents] = React.useState([''])

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'Agent',
      headerName: 'User',
      editable: true,
      type: 'singleSelect',
      valueOptions: agents,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={agents} />
        )
      },
      minWidth: 300
    },
    {
      field: 'Extension',
      headerName: 'Extension',
      editable: true,
      type: 'string',
      minWidth: 180
    },
    {
      field: 'OfferVoicemail',
      headerName: 'Enable Voicemail',
      editable: true,
      type: 'boolean',
      minWidth: 150
    },
    {
      field: 'UseCustomMessage',
      headerName: 'Use Custom Message',
      editable: true,
      type: 'boolean',
      minWidth: 180
    },
    {
      field: 'VoicemailLanguage',
      headerName: 'Voicemail Language',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['', 'English', 'French'],
      minWidth: 180
    },
    {
      field: 'VoicemailMessage',
      headerName: 'Voicemail Message',
      editable: true,
      type: 'string',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'RecordCall',
      headerName: 'Enable call recording',
      editable: true,
      type: 'boolean',
      minWidth: 200
    }
  ]

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      Agent: '',
      Extension: '',
      OfferVoicemail: false,
      VoicemailMessage: '',
      UseCustomMessage: false,
      VoicemailLanguage: '',
      RecordCall: false
    }
    return item
  }

  const checkDuplicateExtensionEntry = (item) => {
    const entryId = item.id
    const entryExtension = item.Extension
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index]
      if (element.id !== entryId) {
        if (entryExtension === element.Extension) {
          return true
        }
      }
    }
    return false
  }

  const validateRow = (item) => {
    console.log('validating row')
    console.log(item)
    // check if the agent field has a value
    if (item.Agent === '') {
      setErrorMessage('Please select a user from the list.')
      return false
    }

    // check if the phone number field has a value
    if (item.Extension === undefined || item.Extension === '') {
      setErrorMessage('Please enter a unique extension for the user.')
      return false
    }

    if (checkDuplicateExtensionEntry(item)) {
      setErrorMessage('This extension is already associated with another user')
      return false
    }

    if (item.UseCustomMessage === true && item.VoicemailMessage.trim().length === 0) {
      setErrorMessage('A voicemail message must be entered if Use Custom Message is true')
      return false
    }

    if (item.UseCustomMessage === true && item.VoicemailLanguage.trim().length === 0) {
      setErrorMessage('A voicemail language must be selected if Use Custom Message is true')
      return false
    }

    return true
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')

    let success = validateRow(updatedRow)
    if (!success) {
      return false
    }

    success = await saveBackofficeExtensionConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting item in main with row id->', id)

    const success = await deleteBackOfficeExtensionConfigEntry(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    }

    return success
  }

  useEffect(() => {
    const doLoadEntries = async () => {
      const entries = await getBackOfficeExtensions()
      setRows(entries)
      setOpenBackdrop(false)
    }
    doLoadEntries()
  }, [])

  useEffect(() => {
    const doLoadAgentList = async () => {
      const entries = await getAgentUsernameList()
      console.log('foound ', entries.length, ' agents')
      setAgents(entries)
    }
    doLoadAgentList()
  }, [])

  return (
        <div style={{ margin: '10px' }}>
            <CssBaseline/>
            <p>Configured Extensions for users who require a dedicated extension which will route calls directly to their user.</p>
            <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='Agent'
                        createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
                        setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage}
            />
        </div>
  )
}
