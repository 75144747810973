import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from './LMDataGrid'
import { loadConnectQueues, loadAgents, loadVoicemailConfig, saveVoicemailConfig, deleteVoicemailConfig, getAgentUsernameList } from '../APIUtils/BackendHandler'
import EditAutocomplete from '../Components/EditAutocomplete'

export default function VoicemailBackOffice () {
  // const [queues, setQueues] = React.useState([''])
  const [agents, setAgents] = React.useState([''])
  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'VM_type',
      headerName: 'Type',
      editable: false,
      type: 'string'
    },
    {
      field: 'Username',
      headerName: 'User',
      editable: true,
      type: 'singleSelect',
      valueOptions: agents,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={agents} />
        )
      },
      width: 250
    },
    {
      field: 'Delivery_channel',
      headerName: 'Delivery Channel',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Task', 'Email'],
      width: 150
    },
    {
      field: 'Delivery_email',
      headerName: 'Delivery Email',
      editable: true,
      type: 'string',
      width: 250
    },
    {
      field: 'Transcribe',
      headerName: 'Transcribe',
      editable: true,
      type: 'boolean'
    }
  ]
  // const [agentMap, setAgentMap] = React.useState([''])
  const [rows, setRows] = React.useState([])
  const [agentMap, setAgentMap] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  // const [queueMap, setQueueMap] = React.useState({})

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      VM_type: 'BackOffice',
      Delivery_channel: '',
      Delivery_email: '',
      Username: '',
      Transcribe: false
    }
    return item
  }

  const validateEmailAddress = (email) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    } else {
      return false
    }
  }

  const checkDuplicateUser = (item) => {
    const entryID = item.id
    const entryAgent = item.Username
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index]
      if (element.id !== entryID) {
        if (entryAgent === element.Username) {
          return true
        }
      }
    }
    return false
  }

  const validateRow = (item) => {
    // check that a delivery
    console.log('validating row')
    // console.log(item)

    // check origin
    if (item.Origin === '') {
      setErrorMessage('Please select a user form the list.')
      return false
    }

    if (checkDuplicateUser(item)) {
      setErrorMessage('An entry already exists for voicemail for \'' + item.Username + '\'.')
      return false
    }

    if (item.Delivery_channel === '') {
      setErrorMessage('Please select a delivery channel.')
      return false
    }

    if ((item.Delivery_channel === 'Task') && (item.Delivery_email !== '')) {
      setErrorMessage('The delivery email should be blank when the delivery channel is set to \'Task\'. Please review the configuration')
      return false
    }

    if ((item.Delivery_channel === 'Email') && (item.Delivery_email === '')) {
      setErrorMessage('The delivery email is mandatory when the delivery channel is set to \'Email\'. Please review the configuration')
      return false
    }
    if ((item.Delivery_channel === 'Email') && (item.Delivery_email !== '')) {
      if (!validateEmailAddress(item.Delivery_email)) {
        setErrorMessage('The email address appears to be invalid. Please enter a valid email address.')
        return false
      }
    }

    return true
  }

  const getAgentIdForUser = (username) => {
    for (let index = 0; index < agentMap.length; index++) {
      const agent = agentMap[index]
      if (agent.Username === username) {
        return agent.id
      }
    }
    return null
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')

    let success = validateRow(updatedRow)
    if (!success) {
      return false
    }

    const agentID = getAgentIdForUser(updatedRow.Username)
    if (agentID == null) {
      console.error('Could not find agent id')
      setErrorMessage('Could find the agent ID in Amazon Connect')
      return false
    }
    updatedRow.Origin = agentID

    success = await saveVoicemailConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    console.log('deleting item in main with row id->', id)

    const success = await deleteVoicemailConfig(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    }
    return true
  }

  const getAgentUsername = (agentID, agents) => {
    for (let index = 0; index < agents.length; index++) {
      const agent = agents[index]
      if (agent.id === agentID) {
        return agent.Username
      }
    }
    return 'User not found'
  }

  useEffect(() => {
    const doLoadVoicemailConfig = async () => {
      const voicemailConfig = await loadVoicemailConfig('BackOffice')
      const agents = await loadAgents()
      setAgentMap(agents)
      for (let index = 0; index < voicemailConfig.length; index++) {
        const entry = voicemailConfig[index]
        const username = getAgentUsername(entry.Origin, agents)
        voicemailConfig[index].Username = username
      }

      setRows(voicemailConfig)
      setOpenBackdrop(false)
    }
    doLoadVoicemailConfig()
  }, [])

  useEffect(() => {
    const doLoadQueues = async () => {
      const CCQueues = await loadConnectQueues()
      const queueNames = []
      // queues = []
      for (let i = 0; i < CCQueues.length; i++) {
        queueNames.push(CCQueues[i].queueName)
      }
      console.log('setting queues')
      // setQueues(queueNames)
      // setQueueMap(CCQueues)
    }
    doLoadQueues()
  }, [])

  useEffect(() => {
    const doLoadAgentList = async () => {
      const entries = await getAgentUsernameList()
      console.log('foound ', entries.length, ' agents')
      setAgents(entries)
    }
    doLoadAgentList()
  }, [])

  return (
        <div style={{ margin: '0px' }}>
            <CssBaseline/>
            <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='HolidayName'
                    createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
                    setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage} hiddenColumns={['Delivery_queue_arn', 'VM_type']}/>

        </div>
  )
}
