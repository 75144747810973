import * as React from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  // GridRowEditStopReasons,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiRef
} from '@mui/x-data-grid'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

export default function LMDataGrid ({
  rows, setRows, basecolumns, EditFieldNameToFocus, createNewItem,
  saveRow, deleteRow, openBackdrop, setOpenBackdrop, errorMessage,
  hiddenColumns = null, updateValueOptionsBeforEditStarts = null
}) {
  // #region success snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccessSnackbar(false)
  }
  // #endregion

  // #region error snackbar
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
  const AlerError = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleCloseErrorSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenErrorSnackbar(false)
  }
  // #endregion

  // #region confirm dlete popup dialog
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const [rowIdToDelete, setRowIdToDelete] = React.useState(null)
  // #endregion

  const [rowModesModel, setRowModesModel] = React.useState({})
  const apiRef = useGridApiRef()

  function EditToolbar (props) {
    const { setRows, setRowModesModel } = props

    const handleClick = () => {
      setOpenBackdrop(true)
      const id = uuidv4()
      setRows((oldRows) => [...oldRows, createNewItem(id)])

      // we need to wait briefly to ensure the data model has updated and the row has been adde
      // then we change page to the last one as this is where the new entry will be
      setTimeout(() => {
        console.log('scrolling to last page')
        const index = Math.ceil(rows.length / 5)
        console.log('last page index=', index)
        apiRef.current.setPage(index)

        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: EditFieldNameToFocus }
        }))

        setOpenBackdrop(false)
      }, 500, id)
    }

    return (
      <GridToolbarContainer>
        <Button color="primary" sx={{ color: window.getConfig.MainColor }} startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
        <GridToolbarFilterButton style={{ color: window.getConfig.MainColor }} />
        <GridToolbarDensitySelector style={{ color: window.getConfig.MainColor }} />
        <GridToolbarExport style={{ color: window.getConfig.MainColor }} />
      </GridToolbarContainer>
    )
  }
  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func
  }

  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     event.defaultMuiPrevented = true
  //     console.log('row edit ended')
  //   }
  // }

  const handleEditClick = (id) => () => {
    console.log('handleEditClick id -> ', id)
    if (updateValueOptionsBeforEditStarts) {
      updateValueOptionsBeforEditStarts(id)
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    console.log('wtf')
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    // console.log('clicked save')
    // actual save is handled in processRowUpdate event
  }

  const handleDeleteOperation = async () => {
    // close the confirmation dialog
    setOpenConfirmDialog(false)

    // do the delete operation
    setOpenBackdrop(true)
    console.log('handleDeleteOperation id->', rowIdToDelete)
    const success = await deleteRow(rowIdToDelete)
    console.log('deleted row')
    console.log('row is was->', rowIdToDelete)
    if (success) {
      setRows(rows.filter((row) => row.id !== rowIdToDelete))
      setSuccessMessage('Delete success.')
      setOpenSuccessSnackbar(true)
      setRowIdToDelete(null)
      // console.log('setting rows model')
      // setRowModesModel({ ...rowModesModel, [rowIdToDelete]: { mode: GridRowModes.View } });
    } else {
      console.log('alert user of delete failure')
      setOpenErrorSnackbar(true)
      setRowIdToDelete(null)
    }
    setOpenBackdrop(false)
  }

  const handleDeleteClick = (id) => async () => {
    console.log('handleDeleteClick id->', id)
    setRowIdToDelete(id)
    console.log('set rowid')

    /* if (window.confirm("Do you want to delete?") == true) {
      handleDeleteOperation(id)
    } else {
      console.log('delete cancelled')
    } */

    setOpenConfirmDialog(true)
    // onsole.log('returning true')
    // return true
    /* setTimeout(() => {
      handleDeleteOperation(id)
    }, 2000); */
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    console.log('row has been updated, should save')
    setOpenBackdrop(true)

    const saveSuccess = await saveRow(newRow)
    if (saveSuccess) {
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
      setOpenBackdrop(false)
      setSuccessMessage('Saved successfully.')
      setOpenSuccessSnackbar(true)
      return updatedRow
    } else {
      setOpenBackdrop(false)
      setOpenErrorSnackbar(true)
    }
    // save was not success so return null to trigger error
    setOpenBackdrop(false)
    return null
  }

  const handleProcessRowUpdateError = React.useCallback((error) => {
    if (error) {
      console.log('error->', console.error(error))
    }
  }, [])

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    ...basecolumns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key='saveIcon'
              label="Save"
              sx={{
                color: window.getConfig.MainColor
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key='cancelIcon'
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key='editIcon'
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key='deleteIcon'
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />
        ]
      }
    }
  ]

  // set the columns that must be hidden
  const visibleColumns = {
    id: false
  }
  if (hiddenColumns != null) {
    for (let index = 0; index < hiddenColumns.length; index++) {
      const columName = hiddenColumns[index]
      visibleColumns[columName] = false
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableRowSelectionOnClick
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        density="compact"
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25
            }
          },
          columns: {
            columnVisibilityModel: visibleColumns
          }
        }}
        apiRef={apiRef}
      />
        <Snackbar open={openSuccessSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbarSuccess}>
            <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseErrorSnackbarSuccess}>
            <AlerError onClose={handleCloseErrorSnackbarSuccess} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </AlerError>
        </Snackbar>
        <Dialog
                open={openConfirmDialog}
                // TransitionComponent={Transition} THIS CAUSES THE DATAGRID TO FREEZE SO DON'T USE IT
                keepMounted
                onClose={() => { setOpenConfirmDialog(false) }}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Please confirm!</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Do you want to delete this item?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { handleDeleteOperation() }}>Yes</Button>
                <Button onClick={() => { setOpenConfirmDialog(false) }}>No</Button>
                </DialogActions>
            </Dialog>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  )
}

LMDataGrid.propTypes = {
  rows: PropTypes.array,
  updateValueOptionsBeforEditStarts: PropTypes.func,
  setRows: PropTypes.func,
  basecolumns: PropTypes.array,
  EditFieldNameToFocus: PropTypes.string,
  createNewItem: PropTypes.func,
  saveRow: PropTypes.func,
  deleteRow: PropTypes.func,
  openBackdrop: PropTypes.bool,
  setOpenBackdrop: PropTypes.func,
  errorMessage: PropTypes.string,
  hiddenColumns: PropTypes.array
}
