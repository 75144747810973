import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import DIDNumbers from '../Components/DIDNumbers'
import Extensions from '../Components/Extensions'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}>
            {value === index && (
              <Box sx={{ p: 0 }}>
                <div>{children}</div>
              </Box>
            )}
        </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const StyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: '#46807C'
  }
})

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BackOffice () {
  const [value, setValue] = useState(0)
  const [openTTSDialog, setOpenTTSDialog] = React.useState(false)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  return (
        <div style={{ margin: '10px' }}>
            <CssBaseline/>
            <Box sx={{ width: '100%', backgroundColor: 'white' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0', backgroundColor: 'white' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                          sx: {
                            backgroundColor: '#46807C'
                          }
                        }}
                        aria-label="secondary tabs example">
                            <StyledTab sx={{ fontSize: '18px' }} label="DID Numbers" {...a11yProps(0)} />
                            <StyledTab sx={{ fontSize: '18px' }} label="Extensions" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <div >
                    <div>Notes:
                        <ul>
                            <li>
                                If voicemail is enabled for specific users then the voicemail configuration for
                                that user must also be completed on the Voicemail configuration page.
                            </li>
                            <li>
                              <div>Custom voicemail messages use Text To Speech (TTS) to play the message. Elements such as
                              names will therefore not always be pronounced 100% correctly. Take care when entering a
                              voicemail message and test the message once set.&nbsp;
                              <div style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }} onClick={() => { setOpenTTSDialog(true) }}>Click here for guidelines on how to specify Text-To-Speech messages.</div>
                              </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <TabPanel value={value} index={0}>
                    <DIDNumbers/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Extensions/>
                </TabPanel>
            </Box>
            <Dialog
                open={openTTSDialog}
                // TransitionComponent={Transition} THIS CAUSES THE DATAGRID TO FREEZE SO DON'T USE IT
                keepMounted
                onClose={() => { setOpenTTSDialog(false) }}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Text-To-Speech guidlines</DialogTitle>
                <DialogContent>
                  <ol>
                    <li>Take care when specifying elements such as names. The Text-To-Speech engine might not
                      get the pronunciation right if it is not a name that it knows.
                    </li>
                    <li>Do not use abbreviations, write out the full words.</li>
                    <li>Write out everything when entering a date, e.g. &apos;fifth of April&apos;. Don&apos;t use formats such as
                      05/05/2024 </li>
                  </ol>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { setOpenTTSDialog(false) }}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
  )
}
