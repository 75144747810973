/* eslint-disable no-prototype-builtins */
import { Auth } from 'aws-amplify'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { DynamoDBClient, ScanCommand, PutItemCommand, DeleteItemCommand, QueryCommand, BatchWriteItemCommand } from '@aws-sdk/client-dynamodb'
import { ConnectClient, ListPhoneNumbersCommand, ListQueuesCommand, ListUsersCommand, ListHoursOfOperationsCommand } from '@aws-sdk/client-connect'
import { SSMClient, GetParametersByPathCommand } from '@aws-sdk/client-ssm'
import { BusinessUnitDetails } from '../DatasourceClasses/BusinessUnitDetails'

// export const checkValidEngageAccount = async () => {
//   try {
//     const apiEndpoint = ' https://engage.' + window.getConfig.EngageRegion + '.localmeasure.com/api/engage/workspace/' + window.getConfig.EngageWorkspaceAlias
//     console.log('apiEndpoint->', apiEndpoint)
//     const response = await fetch(apiEndpoint)
//     // console.log(response)
//     const data = await response.json()
//   } catch (error) {
//     console.error(error)
//     return false
//   }
//   return true
// }

// eslint-disable-next-line no-unused-vars
const getAccessToken = async () => {
  return Auth.currentSession()
    .then(res => {
      const accessToken = res.getAccessToken()
      return accessToken.getJwtToken()
    })
    .catch(error => {
      console.error('Error getting access token->', error)
      return null
    })
}

const getIdToken = async () => {
  return Auth.currentSession()
    .then(res => {
      const idToken = res.getIdToken()
      return idToken.getJwtToken()
    })
    .catch(error => {
      console.error('Error getting id token->', error)
      return null
    })
}

// #region ############ voicemail ############
export async function loadVoicemailConfig (vmType) {
  const voicemailConfig = []

  try {
    // get a dynamodb client
    const client = await getDynamoDBClient()
    // set params
    const params = {
      TableName: window.getConfig.DynamoDBVoicemailTable
    }
    // execute
    const command = new ScanCommand(params)
    const response = await client.send(command)
    // console.log(response)

    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const item = items[key]
        const voicemailConfigEntry = {
          id: item.id.S, // primary key so must be present
          VM_type: '',
          Delivery_channel: '',
          // 'Delivery_queue' : '',
          // 'Delivery_queue_arn' : '',
          Origin: '',
          Transcribe: '',
          Delivery_email: ''
        }
        if (item.hasOwnProperty('Delivery_channel') && item.Delivery_channel != null) {
          voicemailConfigEntry.Delivery_channel = item.Delivery_channel.S
        }
        if (item.hasOwnProperty('Delivery_queue') && item.Delivery_queue != null) {
          voicemailConfigEntry.Delivery_queue = item.Delivery_queue.S
        }
        if (item.hasOwnProperty('Delivery_queue_arn') && item.Delivery_queue_arn != null) {
          voicemailConfigEntry.Delivery_queue_arn = item.Delivery_queue_arn.S
        }
        if (item.hasOwnProperty('Origin') && item.Origin != null) {
          voicemailConfigEntry.Origin = item.Origin.S
        }
        if (item.hasOwnProperty('Transcribe') && item.Transcribe != null) {
          voicemailConfigEntry.Transcribe = item.Transcribe.BOOL
        }
        if (item.hasOwnProperty('Delivery_email') && item.Delivery_email != null) {
          voicemailConfigEntry.Delivery_email = item.Delivery_email.S
        }
        if (item.hasOwnProperty('VM_type') && item.VM_type != null) {
          voicemailConfigEntry.VM_type = item.VM_type.S
        }
        if (voicemailConfigEntry.VM_type === vmType) {
          voicemailConfig.push(voicemailConfigEntry)
        }
      }
    }
  } catch (error) {
    console.error('Error fetching voicemail config')
    console.error(error)
  }
  return voicemailConfig
}

export async function saveVoicemailConfig (configItem) {
  try {
    // get a dynamodb client
    const client = await getDynamoDBClient()
    // console.log(config_item)

    const entry = {
      id: { S: configItem.id },
      VM_type: { S: configItem.VM_type },
      Delivery_channel: { S: configItem.Delivery_channel },
      Origin: { S: configItem.Origin },
      Transcribe: { BOOL: configItem.Transcribe }
    }
    if (configItem.hasOwnProperty('Delivery_queue')) {
      entry.Delivery_queue = { S: configItem.Delivery_queue }
    }
    if (configItem.hasOwnProperty('Delivery_queue_arn')) {
      entry.Delivery_queue_arn = { S: configItem.Delivery_queue_arn }
    }
    if (configItem.hasOwnProperty('Delivery_email')) {
      entry.Delivery_email = { S: configItem.Delivery_email }
    }

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBVoicemailTable,
      Item: entry,
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function deleteVoicemailConfig (id) {
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBVoicemailTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function loadConnectQueues (NextToken = null) {
  let queues = []

  try {
    const client = await getConnectClient()

    const input = {
      InstanceId: window.getConfig.ConnectInstanceID, // required
      QueueTypes: ['STANDARD'],
      MaxResults: 100
    }
    if (NextToken != null) {
      input.NextToken = NextToken
    }
    const command = new ListQueuesCommand(input)
    const response = await client.send(command)
    // console.log(response)
    const QueueSummaryList = response.QueueSummaryList
    if (NextToken == null) {
      queues.push({ queueName: '', queueArn: '' })
    }

    for (let i = 0; i < QueueSummaryList.length; i++) {
      const queue = QueueSummaryList[i]
      const queueData = {
        queueName: queue.Name,
        queueArn: queue.Arn
      }
      queues.push(queueData)
    }

    if (response.hasOwnProperty('NextToken') && response.NextToken !== '') {
      const moreQueues = await loadConnectQueues(response.NextToken)
      queues = queues.concat(moreQueues)
    }
  } catch (error) {
    console.error('Error loading queues')
    console.error(error)
  }

  return queues
}

export async function loadAgents (NextToken = null, connectClient) {
  let agents = []

  try {
    let client = connectClient
    if (client === undefined) {
      client = await getConnectClient()
    }

    const input = {
      InstanceId: window.getConfig.ConnectInstanceID, // required
      MaxResults: 100
    }
    if (NextToken != null) {
      input.NextToken = NextToken
    }
    const command = new ListUsersCommand(input)
    const response = await client.send(command)
    // console.log(response)
    const UserSummaryList = response.UserSummaryList
    if (NextToken == null) {
      agents.push({ id: '', Username: '' })
    }

    for (let i = 0; i < UserSummaryList.length; i++) {
      const agent = UserSummaryList[i]
      agents.push(
        {
          id: agent.Id,
          Username: agent.Username
        })
    }

    if (response.hasOwnProperty('NextToken') && response.NextToken !== '') {
      const moreAgents = await loadAgents(response.NextToken)
      agents = agents.concat(moreAgents)
    }
  } catch (error) {
    console.error('Error loading queues')
    console.error(error)
  }

  return agents
}

export async function loadAgentQueues (NextToken = null, connectClient = undefined) {
  let queues = []

  try {
    let client = connectClient
    if (client === undefined) {
      client = await getConnectClient()
    }

    const input = {
      InstanceId: window.getConfig.ConnectInstanceID, // required
      QueueTypes: ['AGENT'],
      MaxResults: 100
    }
    if (NextToken != null) {
      input.NextToken = NextToken
    }
    const command = new ListQueuesCommand(input)
    const response = await client.send(command)
    // console.log(response)
    const QueueSummaryList = response.QueueSummaryList
    if (NextToken == null) {
      queues.push('')
    }

    for (let i = 0; i < QueueSummaryList.length; i++) {
      const queue = QueueSummaryList[i]
      queues.push(queue.Arn.split('/').pop())
    }

    if (response.hasOwnProperty('NextToken') && response.NextToken !== '') {
      const moreQueues = await loadAgentQueues(response.NextToken, client)
      queues = queues.concat(moreQueues)
    }
  } catch (error) {
    console.error('Error loading queues')
    console.error(error)
  }

  return queues
}

// #endregion ############ voicemail ############

async function getCredentials () {
  // get id token and cognito config
  const idToken = await getIdToken()
  const loginData = {
    [window.getConfig.CognitoID]: idToken
  }

  return fromCognitoIdentityPool({
    clientConfig: { region: window.getConfig.CognitoRegion },
    identityPoolId: window.getConfig.CognitoPoolID,
    logins: loginData
  })
}

async function getDynamoDBClient () {
  const credentials = await getCredentials()
  console.log('getting dynamodb client for region->', window.getConfig.ConnectRegion)
  const client = new DynamoDBClient({
    region: window.getConfig.ConnectRegion,
    credentials
  })
  return client
}

export function getDateString (date) {
  if (date == null) return ''

  const yearString = date.getFullYear().toString()
  let monthString = (date.getMonth() + 1).toString()
  if (monthString.length === 1) {
    monthString = '0' + monthString
  }
  let dayString = date.getDate().toString()
  if (dayString.length === 1) {
    dayString = '0' + dayString
  }

  return yearString + '-' + monthString + '-' + dayString
}

export async function storePublicHoliday (publicHoliday) {
  try {
    const client = await getDynamoDBClient()

    const datestr = getDateString(publicHoliday.Date)
    // console.log('datestr->', datestr)

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBHolidayTable,
      Item: {
        id: { S: publicHoliday.id },
        HolidayName: { S: publicHoliday.HolidayName },
        HolidayDate: { S: datestr },
        Closed: { BOOL: publicHoliday.Closed }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function loadPublicHolidays () {
  try {
    // get a dynamodb client
    const client = await getDynamoDBClient()
    const params = {
      TableName: window.getConfig.DynamoDBHolidayTable
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)
    // console.log(response)

    const holidays = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const holiday = items[key]
        const holdayInfo = {
          id: holiday.id.S,
          HolidayName: holiday.HolidayName.S,
          // Date: new Date(holiday.HolidayDate.S),
          Closed: holiday.Closed.BOOL
        }
        holdayInfo.Date = new Date(holiday.HolidayDate.S + 'T12:00:00')
        console.log('loaded date->', holdayInfo.Date)
        holidays.push(holdayInfo)
      }
    }
    return holidays
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function deletePublicHoliday (id) {
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBHolidayTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

function getEmptyStoreConfig (id) {
  return {
    id,
    store_name: '',
    connect_did: '',
    store_did: '',
    store_id: '',
    store_transfer_phonenumber: '',
    Closed: true
  }
}

export async function getStoreConfig () {
  try {
    const client = await getDynamoDBClient()
    const params = {
      TableName: window.getConfig.DynamoDBStoresTable
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)
    // console.log(response)
    const stores = []

    // process response
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const store = items[key]
        const storeInfo = getEmptyStoreConfig(store.id.S)
        if (store.hasOwnProperty('store_name')) {
          storeInfo.store_name = store.store_name.S
        }
        if (store.hasOwnProperty('connect_did')) {
          storeInfo.connect_did = store.connect_did.S
        }
        if (store.hasOwnProperty('store_did')) {
          storeInfo.store_did = store.store_did.S
        }
        if (store.hasOwnProperty('store_id')) {
          storeInfo.store_id = store.store_id.S
        }
        if (store.hasOwnProperty('store_transfer_phonenumber')) {
          storeInfo.store_transfer_phonenumber = store.store_transfer_phonenumber.S
        }
        if (store.hasOwnProperty('closed')) {
          storeInfo.closed = store.closed.BOOL
        }
        if (store.hasOwnProperty('message')) {
          storeInfo.message = store.message.S
        }
        stores.push(storeInfo)
      }
    }
    return stores
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveStoreConfig (storeInfo) {
  try {
    const client = await getDynamoDBClient()
    console.log(storeInfo)
    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBStoresTable,
      Item: {
        id: { S: storeInfo.id },
        store_name: { S: storeInfo.store_name },
        connect_did: { S: storeInfo.connect_did },
        store_did: { S: storeInfo.store_did },
        store_id: { S: storeInfo.store_id },
        store_transfer_phonenumber: { S: storeInfo.store_transfer_phonenumber },
        message: { S: storeInfo.message },
        closed: { BOOL: storeInfo.closed }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

async function getConnectClient () {
  const credentials = await getCredentials()
  const client = new ConnectClient({
    region: window.getConfig.ConnectRegion,
    credentials
  })
  return client
}

export async function getConnectPhoneNumbers (NextToken = null, connectClient = undefined) {
  try {
    // create client using cognito token as credentials
    let client = connectClient
    if (client === undefined) {
      client = await getConnectClient()
    }

    // now build parameters
    const input = {
      InstanceId: window.getConfig.ConnectInstanceID // required
    }
    if (NextToken != null) {
      input.NextToken = NextToken
    }

    // execute command
    const command = new ListPhoneNumbersCommand(input)
    const response = await client.send(command)
    // console.log(response)

    let phoneNmbers = []
    if (NextToken == null) {
      phoneNmbers.push('')
    }
    const p = response.PhoneNumberSummaryList
    for (const key in p) {
      phoneNmbers.push(p[key].PhoneNumber)
    }

    if (response.hasOwnProperty('NextToken') && response.NextToken !== '') {
      const moreNumbers = await getConnectPhoneNumbers(response.NextToken, client)
      phoneNmbers = phoneNmbers.concat(moreNumbers)
    }

    return phoneNmbers
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function deleteStoreConfig (id) {
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBStoresTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

// #region BackOffice
export async function loadBackOfficeDIDs () {
  try {
    const client = await getDynamoDBClient()
    const params = {
      TableName: window.getConfig.DynamoDBBackOfficeTable
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const entries = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const entry = items[key]
        const entryInfo = {
          id: entry.id.S,
          Agent: entry.Agent_id.S,
          OfferVoicemail: entry.OfferVoicemail.BOOL,
          PhoneNumber: entry.PhoneNumber.S,
          RecordCall: entry.RecordCall.BOOL
        }
        entryInfo.VoicemailMessage = entry?.VoicemailMessage !== undefined ? entry.VoicemailMessage.S : ''
        entryInfo.UseCustomMessage = entry?.UseCustomMessage !== undefined ? entry.UseCustomMessage.BOOL : false
        entryInfo.VoicemailLanguage = entry?.VoicemailLanguage !== undefined ? entry.VoicemailLanguage.S : ''
        entries.push(entryInfo)
      }
    }
    return entries
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function getAgentUsernameList () {
  try {
    const agentInfo = await loadAgents()
    const agents = []
    for (let index = 0; index < agentInfo.length; index++) {
      const agent = agentInfo[index]
      agents.push(agent.Username)
    }
    return agents
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveBackofficeConfig (configEntry) {
  try {
    const client = await getDynamoDBClient()

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBBackOfficeTable,
      Item: {
        id: { S: configEntry.id },
        Agent_id: { S: configEntry.Agent },
        PhoneNumber: { S: configEntry.PhoneNumber },
        OfferVoicemail: { BOOL: configEntry.OfferVoicemail },
        VoicemailLanguage: { S: configEntry.VoicemailLanguage },
        VoicemailMessage: { S: configEntry.VoicemailMessage },
        UseCustomMessage: { BOOL: configEntry.UseCustomMessage },
        RecordCall: { BOOL: configEntry.RecordCall }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function deleteBackOfficeConfigEntry (id) {
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBBackOfficeTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

// #endregion

// #region backoffice extensions
export async function getBackOfficeExtensions () {
  try {
    const client = await getDynamoDBClient()
    const params = {
      TableName: window.getConfig.DynamoDBBackOfficeExtensionsTable
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const entries = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const entry = items[key]
        const entryInfo = {
          id: entry.id.S,
          Agent: entry.Agent_id.S,
          OfferVoicemail: entry.OfferVoicemail.BOOL,
          Extension: entry.Extension.S,
          RecordCall: entry.RecordCall.BOOL
        }
        entryInfo.VoicemailMessage = entry?.VoicemailMessage !== undefined ? entry.VoicemailMessage.S : ''
        entryInfo.UseCustomMessage = entry?.UseCustomMessage !== undefined ? entry.UseCustomMessage.BOOL : false
        entryInfo.VoicemailLanguage = entry?.VoicemailLanguage !== undefined ? entry.VoicemailLanguage.S : ''
        entries.push(entryInfo)
      }
    }
    return entries
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveBackofficeExtensionConfig (configEntry) {
  try {
    const client = await getDynamoDBClient()

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBBackOfficeExtensionsTable,
      Item: {
        id: { S: configEntry.id },
        Agent_id: { S: configEntry.Agent },
        Extension: { S: configEntry.Extension },
        OfferVoicemail: { BOOL: configEntry.OfferVoicemail },
        VoicemailLanguage: { S: configEntry.VoicemailLanguage },
        VoicemailMessage: { S: configEntry.VoicemailMessage },
        UseCustomMessage: { BOOL: configEntry.UseCustomMessage },
        RecordCall: { BOOL: configEntry.RecordCall }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function deleteBackOfficeExtensionConfigEntry (id) {
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBBackOfficeExtensionsTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
// #endregion

export async function getParameter () {
  const credentials = await getCredentials()
  console.log('getting dynamodb client for region->', window.getConfig.ConnectRegion)
  const client = new SSMClient({
    region: window.getConfig.ConnectRegion,
    credentials
  })

  const input = { // GetParametersByPathRequest
    Path: '/lmccc' // required

  }
  const command = new GetParametersByPathCommand(input)
  const response = await client.send(command)
  console.log(response)
}

export async function listHoursOfOperation (NextToken = null, connectClient = undefined) {
  try {
    // get grendentials to be used for the query
    const credentials = await getCredentials()
    console.log('getting dynamodb client for region->', window.getConfig.ConnectRegion)

    // get an amzon connect api client
    let client = connectClient
    if (client === undefined) {
      client = new ConnectClient({
        region: window.getConfig.ConnectRegion,
        credentials
      })
    }

    // define query inputs
    const input = {
      InstanceId: window.getConfig.ConnectInstanceID
    }
    if (NextToken != null) {
      input.NextToken = NextToken
    }
    let hourItems = []

    // create the api command and execute
    const command = new ListHoursOfOperationsCommand(input)
    const response = await client.send(command)

    // iterate over respionse items and process
    const items = response.HoursOfOperationSummaryList
    for (let index = 0; index < items.length; index++) {
      // console.log('processing item')
      const element = items[index]
      const hourItem = {
        Arn: element.Arn,
        Id: element.Id,
        Name: element.Name
      }
      console.log(hourItem)
      hourItems.push(hourItem)
    }
    // check if there are more items to get via api call
    if (response.hasOwnProperty('NextToken') && response.NextToken !== '') {
      console.log('fetching more items')
      const moreHourItems = await listHoursOfOperation(response.NextToken, client)
      hourItems = hourItems.concat(moreHourItems)
    }

    return hourItems
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function getBusinessUnits () {
  try {
    const client = await getDynamoDBClient()

    const params = {
      TableName: window.getConfig.DynamoDBBusinessUnitsTable
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const bunits = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      // iterate over business units
      for (const key in items) {
        const entry = items[key]

        const bu = new BusinessUnitDetails(entry.id.S, entry.BUName.S, '', false, '')

        if (entry.hasOwnProperty('Description')) {
          bu.description = entry.Description.S
        }
        if (entry.hasOwnProperty('ClosedEmergency')) {
          bu.closedEmergency = entry.ClosedEmergency.BOOL
        }
        if (entry.hasOwnProperty('ClosedEmergencyMessage')) {
          bu.closedEmergencyMessage = entry.ClosedEmergencyMessage.S
        }

        bunits.push(bu)
      }
    }
    return bunits
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveBusinessUnit (businessUnit) {
  try {
    // get a dynamodb client
    const client = await getDynamoDBClient()
    // console.log(config_item)

    console.log(businessUnit)

    const bunit = {
      id: { S: businessUnit.id },
      BUName: { S: businessUnit.name },
      Description: { S: businessUnit.description },
      ClosedEmergency: { BOOL: businessUnit.closedEmergency },
      ClosedEmergencyMessage: { S: businessUnit.closedEmergencyMessage }
    }

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBBusinessUnitsTable,
      Item: bunit,
      ReturnValues: 'NONE'
    }
    // save the business unit
    const command = new PutItemCommand(input)
    await client.send(command)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function deleteBusinessUnit (id) {
  console.log('deleteBusinessUnit with id->', id)
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBBusinessUnitsTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

/* export async function loadBuPhoneNumbers () {
  try {
    // get a dynamoDB client
    const client = await getDynamoDBClient()
    // set the table name
    const params = {
      TableName: window.getConfig.DynamoDBBUPhoneNumbersTable
    }
    // execute a table scan to get all items
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const numbers = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      // iterate over numbers
      for (const key in items) {
        const entry = items[key]
        const number = {
          PhoneNumber: entry.PhoneNumber.S,
          BusinessUnit: entry.BusinessUnit.S,
          buid: entry.BUID.S
        }
        numbers.push(number)
      }
    }
    return numbers
  } catch (error) {
    console.error(error)
    return []
  }
} */

export async function loadAllBUQueues () {
  try {
    console.log('loading all BU queues')

    // get a dynamodb cllient
    const client = await getDynamoDBClient()

    const params = {
      TableName: window.getConfig.DynamoDBQueuesTable
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const queues = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      // iterate over business units
      for (const key in items) {
        const entry = items[key]
        const queueInfo = {
          id: entry.id.S,
          QueueName: '',
          QueueArn: '',
          EmergencyClose: false,
          CloseMessage: '',
          BUID: ''
        }
        queueInfo.id = entry.id.S

        if (entry.hasOwnProperty('QueueName')) {
          queueInfo.QueueName = entry.QueueName.S
        }
        if (entry.hasOwnProperty('QueueArn')) {
          queueInfo.QueueArn = entry.QueueArn.S
        }
        if (entry.hasOwnProperty('EmergencyClose')) {
          queueInfo.EmergencyClose = entry.EmergencyClose.BOOL
        }
        if (entry.hasOwnProperty('CloseMessage')) {
          queueInfo.CloseMessage = entry.CloseMessage.S
        }
        if (entry.hasOwnProperty('BUID')) {
          queueInfo.BUID = entry.BUID.S
        }

        queues.push(queueInfo)
      }
    }
    return queues
  } catch (error) {
    console.error(error)
    return []
  }
}

/* export async function loadQueuesForBu (BusinessUnitId) {
  try {
    console.log('loading queues for bu ->', BusinessUnitId)

    // get a dynamoDB client
    const client = await getDynamoDBClient()
    const input = {
      TableName: window.getConfig.DynamoDBQueuesTable,
      IndexName: 'BUID-index',
      Select: 'ALL_ATTRIBUTES',
      KeyConditionExpression: 'BUID = :BusinessUnitId',
      ExpressionAttributeValues: {
        ':BusinessUnitId': { S: BusinessUnitId }
      }
    }
    const command = new QueryCommand(input)
    const response = await client.send(command)
    console.log(' loadQueuesForBu response ->', response)
    const queues = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      // iterate over numbers
      for (const key in items) {
        const entry = items[key]
        const queueInfo = {
          id: entry.id.S,
          QueueName: '',
          QueueArn: '',
          EmergencyClose: false,
          CloseMessage: '',
          BUID: ''
        }
        queueInfo.id = entry.id.S

        if (entry.hasOwnProperty('QueueName')) {
          queueInfo.QueueName = entry.QueueName.S
        }
        if (entry.hasOwnProperty('QueueArn')) {
          queueInfo.QueueArn = entry.QueueArn.S
        }
        if (entry.hasOwnProperty('EmergencyClose')) {
          queueInfo.EmergencyClose = entry.EmergencyClose.BOOL
        }
        if (entry.hasOwnProperty('CloseMessage')) {
          queueInfo.CloseMessage = entry.CloseMessage.S
        }
        if (entry.hasOwnProperty('BUID')) {
          queueInfo.BUID = entry.BUID.S
        }

        queues.push(queueInfo)
      }
    }
    return queues
  } catch (error) {
    console.error(error)
    return []
  }
} */

export async function deleteQueueFromBU (id) {
  console.log('deleting queue with id -> ', id, ' from bu')
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBQueuesTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function saveBuQueueConfig (QueueConfig, bunit) {
  try {
    // get a dynamodb client
    const client = await getDynamoDBClient()

    // build the item to save
    const queueInfo = {
      id: { S: QueueConfig.id },
      QueueName: { S: QueueConfig.QueueName },
      QueueArn: { S: QueueConfig.QueueArn },
      EmergencyClose: { BOOL: QueueConfig.EmergencyClose },
      CloseMessage: { S: QueueConfig.CloseMessage },
      BUID: { S: bunit.id }
    }

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBQueuesTable,
      Item: queueInfo,
      ReturnValues: 'NONE'
    }
    // save the business unit
    const command = new PutItemCommand(input)
    await client.send(command)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function loadPhoneNumbersForBu (BusinessUnitId) {
  try {
    console.log('loading phone numbers for bu ->', BusinessUnitId)

    // get a dynamoDB client
    const client = await getDynamoDBClient()
    const input = {
      TableName: window.getConfig.DynamoDBBUPhoneNumbersTable,
      IndexName: 'BUID-index',
      Select: 'ALL_ATTRIBUTES',
      KeyConditionExpression: 'BUID = :BusinessUnitId',
      ExpressionAttributeValues: {
        ':BusinessUnitId': { S: BusinessUnitId }
      }
    }
    const command = new QueryCommand(input)
    const response = await client.send(command)
    console.log(' loadPhoneNumbersForBu response ->', response)
    const numbers = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      // iterate over numbers
      for (const key in items) {
        const entry = items[key]
        numbers.push(entry.PhoneNumber.S)
      }
    }
    return numbers
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveNumbersForBU (numbers, BusinessUnitName, BusinessUnitId) {
  try {
    // get a dynamoDB client
    const client = await getDynamoDBClient()

    // create the base input structure
    const tableName = window.getConfig.DynamoDBBUPhoneNumbersTable
    const input = {
      RequestItems: {
      }
    }
    input.RequestItems[tableName] = []

    // loop over the numbers to be added
    for (let index = 0; index < numbers.length; index++) {
      // for each number, create a put request and add to the input structure under music
      const item = {
        PutRequest: {
          Item: {
            PhoneNumber: {
              S: numbers[index]
            },
            BUID: {
              S: BusinessUnitId
            },
            BusinessUnit: {
              S: BusinessUnitName
            }
          }
        }
      }
      input.RequestItems[tableName].push(item)
    }
    // we should now have all the numbers we need to store in the input structure
    const command = new BatchWriteItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function loadBUHolidays (BusinessUnitId) {
  console.log('loading holidays for bu ->', BusinessUnitId)

  try {
  // get a dynamoDB client
    const client = await getDynamoDBClient()
    const input = {
      TableName: window.getConfig.DynamoDBBUHolidaysTable,
      Select: 'ALL_ATTRIBUTES',
      IndexName: 'BUID-index',
      KeyConditionExpression: 'BUID = :BusinessUnitId',
      ExpressionAttributeValues: {
        ':BusinessUnitId': { S: BusinessUnitId }
      }
    }
    const command = new QueryCommand(input)
    const response = await client.send(command)
    console.log(' loadBUHolidays response ->', response)
    const holidays = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      // iterate over numbers
      for (const key in items) {
        const entry = items[key]
        const holiday = {
          id: entry.id.S,
          BUID: BusinessUnitId,
          HolidayName: '',
          HolidayDate: undefined,
          Closed: false,
          CloseMessage: ''
        }
        if (entry.hasOwnProperty('HolidayName')) {
          holiday.HolidayName = entry.HolidayName.S
        }
        if (entry.hasOwnProperty('HolidayDate')) {
          console.log('Timezone->', Intl.DateTimeFormat().resolvedOptions().timeZone)
          console.log('loading date with string', entry.HolidayDate.S)
          console.log('testing old load')
          holiday.HolidayDate = new Date(entry.HolidayDate.S + 'T12:00:00')
          console.log('loaded date->', holiday.HolidayDate)
        }
        if (entry.hasOwnProperty('Closed')) {
          holiday.Closed = entry.Closed.BOOL
        }
        if (entry.hasOwnProperty('CloseMessage')) {
          holiday.CloseMessage = entry.CloseMessage.S
        }
        holidays.push(holiday)
      }
    }
    return holidays
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveBUHoliday (publicHoliday) {
  console.log('saving holiday for bu ->', publicHoliday.id)
  console.log(publicHoliday)
  try {
    const client = await getDynamoDBClient()

    const datestr = getDateString(publicHoliday.HolidayDate)
    // console.log('datestr->', datestr)

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBBUHolidaysTable,
      Item: {
        id: { S: publicHoliday.id },
        BUID: { S: publicHoliday.BUID },
        HolidayName: { S: publicHoliday.HolidayName },
        HolidayDate: { S: datestr },
        Closed: { BOOL: publicHoliday.Closed },
        CloseMessage: { S: publicHoliday.CloseMessage }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function deleteBUHoliday (id) {
  console.log('deleting holiday with id -> ', id, ' from bu')
  try {
    const client = await getDynamoDBClient()
    const params = {
      Key: {
        id: { S: id }
      },
      TableName: window.getConfig.DynamoDBBUHolidaysTable,
      ReturnValues: 'NONE'
    }
    const command = new DeleteItemCommand(params)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
