import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from './LMDataGrid'
import { loadConnectQueues, loadVoicemailConfig, saveVoicemailConfig, deleteVoicemailConfig } from '../APIUtils/BackendHandler'
import EditAutocomplete from '../Components/EditAutocomplete'

export default function VoicemailContactCenter () {
  const [queues, setQueues] = React.useState([''])
  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'VM_type',
      headerName: 'Type',
      editable: false,
      type: 'string'
    },
    {
      field: 'Origin',
      headerName: 'Origin queue',
      editable: true,
      type: 'singleSelect',
      valueOptions: queues,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={queues} />
        )
      },
      width: 250
    },
    {
      field: 'Delivery_channel',
      headerName: 'Delivery Channel',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Task', 'Email'],
      width: 150
    },
    {
      field: 'Delivery_email',
      headerName: 'Delivery Email',
      editable: true,
      type: 'string',
      width: 250
    },
    {
      field: 'Delivery_queue',
      headerName: 'Delivery Queue',
      editable: true,
      type: 'singleSelect',
      valueOptions: queues,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={queues} />
        )
      },
      width: 250
    },
    {
      field: 'Delivery_queue_arn',
      headerName: 'Delivery queue arn',
      editable: false,
      type: 'string',
      flex: 100,
      hide: true
    },
    {
      field: 'Transcribe',
      headerName: 'Transcribe',
      editable: true,
      type: 'boolean'
    }
  ]
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [queueMap, setQueueMap] = React.useState({})

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      VM_type: 'ContactCenter',
      Delivery_channel: '',
      Delivery_queue: '',
      Delivery_queue_arn: '',
      Delivery_email: '',
      Origin: '',
      Transcribe: false
    }
    return item
  }

  const validateEmailAddress = (email) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    } else {
      return false
    }
  }

  const checkDuplicateOrigin = (item) => {
    const entryID = item.id
    const entryOrigin = item.Origin
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index]
      if (element.id !== entryID) {
        if (entryOrigin === element.Origin) {
          return true
        }
      }
    }
    return false
  }

  const validateRow = (item) => {
    // check that a delivery
    console.log('validating row')
    console.log(item)

    // check origin
    if (item.Origin === '') {
      setErrorMessage('Please select an originating queue.')
      return false
    }

    if (checkDuplicateOrigin(item)) {
      setErrorMessage('An entry already exxists for voicemail originating from \'' + item.Origin + '\'.')
      return false
    }

    if (item.Delivery_channel === '') {
      setErrorMessage('Please select a delivery channel.')
      return false
    }

    if ((item.Delivery_channel === 'Task') && (item.Delivery_email !== '')) {
      setErrorMessage('The delivery email should be blank when the delivery channel is set to \'Task\'. Please review the configuration')
      return false
    }
    if ((item.Delivery_channel === 'Task') && (item.Delivery_queue === '')) {
      setErrorMessage('The delivery queue is mandatory when the delivery channel is  \'Task\'.')
      return false
    }

    if ((item.Delivery_channel === 'Email') && (item.Delivery_email === '')) {
      setErrorMessage('The delivery email is mandatory when the delivery channel is set to \'Email\'. Please review the configuration')
      return false
    }
    if ((item.Delivery_channel === 'Email') && (item.Delivery_email !== '')) {
      if (!validateEmailAddress(item.Delivery_email)) {
        setErrorMessage('The email address appears to be invalid. Please enter a valid email address.')
        return false
      }
    }

    if ((item.Delivery_channel === 'Email') && (item.Delivery_queue !== '')) {
      setErrorMessage('The delivery queue should be blank when the delivery channel is set to \'Email\'.\nPlease review the configuration')
      return false
    }
    return true
  }

  const getArnForQueueName = (queueName) => {
    for (let index = 0; index < queueMap.length; index++) {
      const element = queueMap[index]
      if (element.queueName === queueName) {
        return element.queueArn
      }
    }
    return ''
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')

    let success = validateRow(updatedRow)
    if (!success) {
      return false
    }

    // lookup the queue arn of the delivery IF the elivery queue is set
    if (updatedRow.Delivery_queue !== '') {
      updatedRow.Delivery_queue_arn = getArnForQueueName(updatedRow.Delivery_queue)
    }

    console.log(updatedRow)

    success = await saveVoicemailConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting item in main with row id->', id)

    const success = await deleteVoicemailConfig(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    }

    // return success
    return true
  }

  useEffect(() => {
    const doLoadVoicemailConfig = async () => {
      const voicemailConfig = await loadVoicemailConfig('ContactCenter')
      setRows(voicemailConfig)
      setOpenBackdrop(false)
    }
    doLoadVoicemailConfig()
  }, [])

  useEffect(() => {
    const doLoadQueues = async () => {
      const CCQueues = await loadConnectQueues()
      const queueNames = []
      // queues = []
      for (let i = 0; i < CCQueues.length; i++) {
        queueNames.push(CCQueues[i].queueName)
      }
      console.log('setting queues')
      setQueues(queueNames)
      setQueueMap(CCQueues)
    }
    doLoadQueues()
  }, [])

  // useEffect(()=>{
  //     const doLoadAgents = async () =>{
  //         let agents = await loadAgents()
  //         //console.log(agents)
  //     }
  //     doLoadAgents()
  // },[])

  return (
        <div style={{ margin: '0px' }}>
            <CssBaseline/>
            <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='HolidayName'
                    createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
                    setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage} hiddenColumns={['Delivery_queue_arn', 'VM_type']}/>

        </div>
  )
}
