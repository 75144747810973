import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { StyledSwitch, StyledTextField, StyledButton } from './StyledComponents'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Queues from '../Components/Queues'
import Holidays from '../Components/Holidays'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
          {value === index && (
            <Box sx={{ p: 1 }}>
              <div>{children}</div>
            </Box>
          )}
      </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const StyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: '#46807C'
  }
})

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BUDetails ({
  businessUnit, updateDataSourceAfterSave, setOpenBackdrop, notifyParentOfModifiedStatus
}) {
  // if a null object is passed in we don't want to display anything
  if (businessUnit === undefined) {
    return (<div/>)
  }

  const [bUnit, setBUnit] = React.useState()
  const [value, setValue] = React.useState(0)
  const [buName, setBuName] = React.useState('')
  const [buDescription, setBuDescription] = React.useState('')
  const [buEmergencyClose, setBuEmergencyClose] = React.useState(false)
  const [buEmergencyCloseMessage, setBuEmergencyCloseMessage] = React.useState('')
  const [isModified, setIsModified] = React.useState(false)

  // #region success snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccessSnackbar(false)
  }
  // #endregion

  // #region error snackbar
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const AlerError = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleCloseErrorSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenErrorSnackbar(false)
  }
  // #endregion

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  // const [isModified, setIsModified] = React.useState(false)

  // sets the local value for a BU when passed in from the parent
  useEffect(() => {
    console.log('useEffect setting bUnit')
    if (businessUnit) {
      setBUnit(businessUnit)
      setBuName(businessUnit.name)
      setBuDescription(businessUnit.description)
      setBuEmergencyClose(businessUnit.closedEmergency)
      setBuEmergencyCloseMessage(businessUnit.closedEmergencyMessage)
      setIsModified(businessUnit.isNew)
      notifyParentOfModifiedStatus(businessUnit.isNew)
    }
  }, [businessUnit])

  if (bUnit === undefined) {
    return (
        <div></div>
    )
  }

  const handleEmergencyCloseChange = (event) => {
    setBuEmergencyClose(event.target.checked)
    setIsModified(businessUnit.closedEmergency !== event.target.checked) // check if new config differs from saves
    notifyParentOfModifiedStatus(businessUnit.closedEmergency !== event.target.checked) // notify parent
  }

  const handleNameChange = (newValue) => {
    setBuName(newValue)
    setIsModified(businessUnit.name !== newValue) // check if new config differs from saves
    notifyParentOfModifiedStatus(businessUnit.name !== newValue)
  }

  const handleDescriptionChange = (newValue) => {
    setBuDescription(newValue)
    setIsModified(businessUnit.description !== newValue) // check if new config differs from saves
    notifyParentOfModifiedStatus(businessUnit.description !== newValue)
  }

  const handleClosedEmergencyMessageChange = (newValue) => {
    setBuEmergencyCloseMessage(newValue)
    setIsModified(businessUnit.closedEmergencyMessage !== newValue) // check if new config differs from saves
    notifyParentOfModifiedStatus(businessUnit.closedEmergencyMessage !== newValue)
  }

  const getBUSectionHeading = () => {
    // return the header for the BU Config section, indicating whether the currently selected item is modified
    if (isModified === true) {
      return 'Business Unit configuration (Unsaved changes)'
    } else {
      return 'Business Unit configuration'
    }
  }

  // const test = (businessUnit) => {
  //   console.log('bu has ', businessUnit.queues.length)
  //   for (let index = 0; index < businessUnit.queues.length; index++) {
  //     const queue = businessUnit.queues[index]
  //     console.log('queue->', queue.QueueName)
  //   }
  // }

  const saveBUnit = async (businessUnit, updateSource) => {
    if ((buEmergencyClose === true) && (buEmergencyCloseMessage.length === 0)) {
      setErrorMessage('Please enter an Emergency message when activating the Emergency close functionality')
      setOpenErrorSnackbar(true)
      return false
    }

    setOpenBackdrop(true)
    // const success = await saveBusinessUnit(businessUnit)
    bUnit.name = buName
    bUnit.description = buDescription
    bUnit.closedEmergency = buEmergencyClose
    bUnit.closedEmergencyMessage = buEmergencyCloseMessage

    const success = await bUnit.save()
    if (success) {
      console.log('save success')
      if (updateSource === true) {
        businessUnit.isNew = false
        updateDataSourceAfterSave(businessUnit)
      }
      setIsModified(false)
      notifyParentOfModifiedStatus(false)
      setSuccessMessage('Saved')
      setOpenSuccessSnackbar(true)
    } else {
      console.log('save FAILED')
      setErrorMessage('Failed to save item')
      setOpenErrorSnackbar(true)
    }
    setOpenBackdrop(false)
    return success
  }

  const showHolidays = (window.getConfig?.DynamoDBBUHolidaysTable &&
                        window.getConfig?.DynamoDBBUHolidaysTable !== 'NONE' &&
                        window.getConfig?.DynamoDBBUHolidaysTable !== '')
  const showPhoneNumbers = false

  return (
    <div style={{ margin: '10px', marginTop: '20px' }}>
        <CssBaseline/>
        <h3>{getBUSectionHeading()} </h3>
        <Grid container spacing={5} rowSpacing={3} >
            <Grid xs={4}>
                <StyledTextField fullWidth label="Business Unit Name" variant="standard" value={buName}
                  onChange={(event) => { handleNameChange(event.target.value) }} />
            </Grid>
            <Grid xs={8}>
                <StyledTextField fullWidth label="Description" variant="standard" value={buDescription}
                onChange={(event) => handleDescriptionChange(event.target.value)} />
            </Grid>

            <Grid xs={4}>
              <FormGroup>
                <FormControlLabel control={
                  <StyledSwitch
                    checked={buEmergencyClose}
                    onChange={handleEmergencyCloseChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } label="Emergency close" />
              </FormGroup>
            </Grid>
            <Grid xs>
                <StyledTextField fullWidth label="Emergency closed message" variant="standard" value={buEmergencyCloseMessage}
                  onChange={(event) => handleClosedEmergencyMessageChange(event.target.value)} />
            </Grid>
            <Grid xs='auto'>
              <StyledButton disabled={!isModified} variant="contained" onClick={() => { saveBUnit(bUnit, true) }}>Save</StyledButton>
            </Grid>
            <Grid xs={12} >
              <Box sx={{ borderBottom: 0, borderColor: 'divider', position: 'sticky', top: '0' }}>
                      <Tabs
                          value={value}
                          onChange={handleChange}
                          // textColor={'rgb(70, 128, 124)'}
                          TabIndicatorProps={{
                            sx: {
                              backgroundColor: '#46807C'
                            }
                          }}
                          aria-label="secondary tabs example">
                            <StyledTab label="Queues" value={0} {...a11yProps(0)} />
                            { showHolidays &&
                              <StyledTab label="Holidays" value={1} {...a11yProps(1)} />
                            }
                            { showPhoneNumbers &&
                              <StyledTab label="Phone Numbers" value={2} {...a11yProps(2)} />
                            }
                      </Tabs>
                </Box>
                <TabPanel value={value} index={0} >
                    <Queues businessUnit={businessUnit} />
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <Holidays businessUnit={businessUnit}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div>PhoneNumbers</div>
                  {/*
                  <PhoneNumbers businessUnit={bUnit} addPhoneNumber={addPhoneNumber} removePhoneNumber={removePhoneNumber} unusedPhoneNumbers={phoneNumbers}/>
                        */}
                </TabPanel>
              {
              /*  */
              }
            </Grid>
        </Grid>
        <Snackbar open={openSuccessSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbarSuccess}>
            <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseErrorSnackbarSuccess}>
            <AlerError onClose={handleCloseErrorSnackbarSuccess} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </AlerError>
        </Snackbar>
    </div>
  )
}
BUDetails.propTypes = {
  businessUnit: PropTypes.object,
  updateDataSourceAfterSave: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  notifyParentOfModifiedStatus: PropTypes.func
}
