import React, { useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { useLoaderData, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import BUDetails from '../Components/BUDetails'
import { v4 as uuidv4 } from 'uuid'
import { getSelectedBUListItem, getBUListItem } from '../Components/BUListItems'
import { BusinessUnitDetails } from '../DatasourceClasses/BusinessUnitDetails'
import {
  getBusinessUnits
} from '../APIUtils/BackendHandler'

export async function buLoader ({ params }) {
  console.log('in loader')
  let id
  if (params.buid !== 'noselection') {
    console.log('loader -> got buid')
    id = params.buid
  } else {
    id = undefined
  }

  const loaderData = {
    buid: id
  }
  return { loaderData }
}

export default function BusinessUnit () {
  console.log('in BusinessUnits')
  const navigate = useNavigate()
  const { loaderData } = useLoaderData()
  console.log('buid->', loaderData.buid)
  const [bunits, setBunits] = useState()
  const [selectedBU, setSeletecBU] = React.useState(undefined)
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [isModified, setIsModified] = React.useState(false)

  // #region success snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccessSnackbar(false)
  }
  // #endregion

  // #region error snackbar
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const AlerError = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleCloseErrorSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenErrorSnackbar(false)
  }
  // #endregion

  // #region confirm dlete popup dialog
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const [itemIndexToDelete, setItemIndexToDelete] = React.useState(undefined)
  // #endregion

  // #region confirm dlete popup dialog
  const [openConfirmNavigateDialog, setOpenConfirmNavigateDialog] = React.useState(false)
  const [itemIndexToNavigateTo, setItemIndexToNavigateTo] = React.useState(undefined)
  // #endregion

  // The below useEffect fetches the data from dynamoDB on page load
  useEffect(() => {
    const loadBusinessUnits = async () => {
      // first load the business units
      console.log('loading bus')
      const businessUnits = await getBusinessUnits()
      businessUnits.sort(businessUnitSorter)
      console.log('setting bus after load')
      setBunits(businessUnits)
      // Now load the connect phone numbers
      // const phoneNumbers = await getConnectPhoneNumbers()
      // setConnectNumbers(phoneNumbers)
      // Now load the connect queues
      // const queues = await loadConnectQueues()
      // setConnectQueues(queues)
      setOpenBackdrop(false)
    }
    setOpenBackdrop(true)
    loadBusinessUnits()
  }, [loaderData])

  // below is used to sort bunits
  const businessUnitSorter = (a, b) => {
    console.log('a.name->', a.name)
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    } else {
      return -1
    }
  }

  // the below use effect will run whenever bunits change and figure out the selected bu
  useEffect(() => {
    if (bunits === undefined) return
    // console.log('searching for bu with id->', loaderData.buid)
    // iterate over bunits and look for the one with the same id as the id passed in bvia the loader
    let found = false
    for (let index = 0; index < bunits.length; index++) {
      const businessUnit = bunits[index]
      if (businessUnit.id === loaderData.buid) {
        // console.log('found selected buid')
        setSeletecBU(businessUnit)
        found = true
        break
      }
    }
    // console.log('found = ', found)
    if (!found) {
      // console.log('setting selected bu to undefined')
      setSeletecBU(undefined)
    }
  }, [bunits])

  // this sets the selected BU index
  const handleBuListItemClick = (event, index) => {
    // check if current item is modified and if so ask for confirmation before navigating
    if (isModified) {
      setItemIndexToNavigateTo(index)
      setOpenConfirmNavigateDialog(true)
      return
    }
    // item is not modified so navigate
    navigate('/BusinessUnit/' + bunits[index].id)
  }
  const navigateToSelectedItem = () => {
    setOpenConfirmNavigateDialog(false)
    navigate('/BusinessUnit/' + bunits[itemIndexToNavigateTo].id)
    setItemIndexToNavigateTo(undefined)
  }

  const handleDeleteBUClick = (event, index) => {
    // set the index of the item for which delete was clicked
    console.log('clicked to delete bu with index ->', index)
    setItemIndexToDelete(index)
    // open the confirm dialog which will ask the user to confirm the delete operation
    setOpenConfirmDialog(true)
  }

  const handleDeleteBU = async () => {
    // close the dialog window
    setOpenConfirmDialog(false)
    // open the backdrop with progress spinner
    setOpenBackdrop(true)

    let deletingCurrentItem = false
    if (bunits[itemIndexToDelete].id === selectedBU.id) {
      console.log('deleting current item')
      deletingCurrentItem = true
    }
    console.log('deleting current item->', deletingCurrentItem)

    // calculate new id to load after index
    let newid = ''
    if (bunits.length === 1) {
      // if there is only 1 item left then there will be no item to display post delete
      newid = 'noselection'
    } else if (itemIndexToDelete === 0) {
      // if we get here then there is more than one item. If we delete the first item then we need to navigate to the next
      newid = bunits[1].id
    } else if (itemIndexToDelete < bunits.length - 2) {
      // if the item to be deleted is anyone up to the second last, then move up with 1
      newid = bunits[itemIndexToDelete + 1].id
    } else {
      // we are deleting the last item on the list so move back one
      newid = bunits[itemIndexToDelete - 1].id
    }
    console.log('newid->', newid)

    // get the bu to delete and delete from database
    const buUnitToDelete = bunits[itemIndexToDelete]
    const success = await buUnitToDelete.delete()
    console.log('handleDeleteBU: success=', success)
    setItemIndexToDelete(-1)
    if (success) {
      console.log('delete success')
      setSuccessMessage('Deleted')
      setOpenSuccessSnackbar(true)
      if (deletingCurrentItem) {
        console.log('navigate post delete as current item was deleted')
        if (newid === 'noselection') {
          setSeletecBU(undefined)
        }
        navigate('/BusinessUnit/' + newid)
      } else {
        setBunits(bunits.filter((bunit) => bunit.id !== bunits[itemIndexToDelete].id).sort(businessUnitSorter))
      }
    } else {
      console.log('delete FAILED')
      setErrorMessage('Failed to delete item. Check your internet connectivity and try again.')
      setOpenErrorSnackbar(true)
    }
    setOpenBackdrop(false)
  }

  const addnewBU = async () => {
    setOpenBackdrop(true)
    console.log('creating new BU')
    const id = uuidv4()
    const newBusinessUnit = new BusinessUnitDetails(id, 'New Entry', '', false, '')

    const newval = [...bunits, newBusinessUnit]
    newval.sort(businessUnitSorter)
    console.log(newval)
    setBunits(newval)

    console.log('bu added')
    const response = await newBusinessUnit.save()
    console.log('response=', response)
    setOpenBackdrop(false)
  }

  const updateDataSourceAfterSave = (businessUnit) => {
    let i = 0
    const newBUnits = []
    console.log('updateDataSourceAfterSave bunits length=', bunits.length)
    while (i < bunits.length) {
      if (bunits[i].id === businessUnit.id) {
        newBUnits.push(businessUnit)
      } else {
        newBUnits.push(bunits[i])
      }
      i++
    }
    setBunits(newBUnits.sort(businessUnitSorter))
  }

  const getDeleteDialogMessage = () => {
    if ((itemIndexToDelete !== undefined) && (itemIndexToDelete !== -1)) {
      return 'Do you want to delete' + bunits[itemIndexToDelete].name + '?'
    } else {
      return ''
    }
  }

  // function removeNumberFromAvailableList (phoneNumber) {
  //   // temporarily removes a number from the available list when assign to a BU
  //   // when the BU is saved the available numbers will be re-evaluated by the useEffect
  //   // setAvailableNumbers(availableNumbers.filter((number) => number !== phoneNumber))
  // }

  // function addNumberToAvailableList (phoneNumber) {
  //   // temporarily add a number back to the available list when removing it from a BU
  //   // when the BU is saved the available numbers will be re-evaluated by the useEffect
  //   // setAvailableNumbers([...availableNumbers, phoneNumber])
  // }

  // checks if a given number is assigned to a business unit
  // const doesBusinessUnitHaveNumber = (businessUnit, number) => {
  //   // iterate over numbers assigned to the business unit to check if the number has been assigned to this unit
  //   for (let index = 0; index < businessUnit.PhoneNumbers.length; index++) {
  //     if (number === businessUnit.PhoneNumbers[index]) {
  //       return true
  //     }
  //   }
  //   return false
  // }

  // // checks if a given number is assigned to a business unit
  // const doesBusinessUnitHaveQueue = (businessUnit, queueArn) => {
  //   // iterate over numbers assigned to the business unit to check if the number has been assigned to this unit
  //   for (let index = 0; index < businessUnit.Queues.length; index++) {
  //     const queue = businessUnit.Queues[index]
  //     if (queueArn === queue.QueueArn) {
  //       return true
  //     }
  //   }
  //   return false
  // }

  return (
    <div style={{ margin: '10px', marginTop: '20px' }}>
        <CssBaseline/>
        <Grid container spacing={1} sx={{ height: 'calc(100vh - 100px)' }}>
            <Grid xs={12} sm={2}>
                <Box sx={{
                  maxHeight: 'calc(100vh - 110px)',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                    <List dense={true} sx={{ width: 'auto', height: 'calc(100vh - 100)' }}
                    subheader={
                      <ListItem>
                                <ListItemText>Business Units</ListItemText>
                                    <IconButton edge='end' onClick={(event) => addnewBU(event)}>
                                            <AddIcon />
                                    </IconButton>
                            </ListItem>
                    }>
                        { (bunits)
                          ? bunits.map((bunit, index) => {
                            if ((selectedBU !== undefined) && selectedBU.id === bunit.id) {
                              return getSelectedBUListItem(index, bunit, true, handleDeleteBUClick, handleBuListItemClick)
                            } else {
                              return getBUListItem(index, bunit, false, handleDeleteBUClick, handleBuListItemClick)
                            }
                          })
                          : <div/>
                      }

                    </List>
                </Box>
            </Grid>
            <Grid xs={12} sm={10}>
                <Box sx={{
                  boxShadow: 1,
                  height: 'calc(100vh - 100px)',
                  backgroundColor: 'white',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  paddingLeft: '5px'
                }}>
                    { (selectedBU !== undefined)
                      ? <BUDetails businessUnit={selectedBU}
                      updateDataSourceAfterSave={updateDataSourceAfterSave}
                      setOpenBackdrop={setOpenBackdrop}
                      notifyParentOfModifiedStatus={setIsModified} />
                      : <div/>
                    }
                </Box>
            </Grid>

        </Grid>
        <Snackbar open={openSuccessSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbarSuccess}>
            <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseErrorSnackbarSuccess}>
            <AlerError onClose={handleCloseErrorSnackbarSuccess} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </AlerError>
        </Snackbar>
        <Dialog
                open={openConfirmDialog}
                // TransitionComponent={Transition} THIS CAUSES THE DATAGRID TO FREEZE SO DON'T USE IT
                keepMounted
                onClose={() => { setOpenConfirmDialog(false) }}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Please confirm!</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {getDeleteDialogMessage()}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { handleDeleteBU() }}>Yes</Button>
                <Button onClick={() => { setOpenConfirmDialog(false); setItemIndexToDelete(undefined) }}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmNavigateDialog}
                // TransitionComponent={Transition} THIS CAUSES THE DATAGRID TO FREEZE SO DON'T USE IT
                keepMounted
                onClose={() => { setOpenConfirmNavigateDialog(false) }}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    The item you are currently viewing has unsaved changes which will be lost if you navigate away.
                    Do you wish to proced?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { navigateToSelectedItem() }}>Proceed</Button>
                <Button onClick={() => { setOpenConfirmNavigateDialog(false); setItemIndexToNavigateTo(undefined) }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  )
}
