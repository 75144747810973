import { saveBusinessUnit, deleteBusinessUnit, deleteQueueFromBU, deleteBUHoliday } from '../APIUtils/BackendHandler'

export class BusinessUnitDetails {
  constructor (id, name, description, closedEmergency, closedEmergencyMessage) {
    this.id = id
    this.name = name
    this.description = description
    this.closedEmergency = closedEmergency
    this.closedEmergencyMessage = closedEmergencyMessage
    this.queues = []
    this.holidays = []
  }

  async save () {
    console.log('BusinessUnitDetails -> save')
    const response = await saveBusinessUnit(this)
    return response
  }

  async delete () {
    console.log('BusinessUnitDetails -> delete')

    // first delete all the associsted queue config from dynamodb and check if deletion was a success
    let response = await this.#deleteAllQueues()
    if (!response) {
      // notify user of failure to delete
      return false
    }
    // now delete all the associated holidays from dynamodb and check if success
    response = await this.#deleteAllHolidays()
    if (!response) {
      // notify user of failure to delete
      return false
    }
    console.log('bu queues and holiday deleted, now deleting bu')
    // if the queue config was successfully deleted then delete the business unit from dynamodb
    response = await deleteBusinessUnit(this.id)
    return response
  }

  #queuesContains (queue) {
    for (let index = 0; index < this.queues.length; index++) {
      const element = this.queues[index]
      if (element.id === queue.id) {
        return index
      }
    }

    return -1
  }

  #holidaysContains (holiday) {
    for (let index = 0; index < this.holidays.length; index++) {
      const element = this.holidays[index]
      if (element.id === holiday.id) {
        return index
      }
    }

    return -1
  }

  clearQueues () {
    this.queues.length = 0
  }

  clearHolidays () {
    this.holidays.length = 0
  }

  addQueue (queue) {
    if (this.#queuesContains(queue) === -1) { // item not found
      console.log('adding ', queue.QueueName, ' bu')
      this.queues.push(queue)
    } else {
      console.log('bu already contains queue')
    }
  }

  addHoliday (holiday) {
    if (this.#holidaysContains(holiday) === -1) {
      console.log('add holiday ', holiday.HolidayName)
      this.holidays.push(holiday)
    } else {
      console.log('holiday already added')
    }
  }

  async #deleteAllQueues () {
    // loop over queues and delete them one by one

    while (this.queues.length > 0) {
      const queue = this.queues.pop()
      console.log('#deleteAllQueues queue ->')
      console.log(queue)
      console.log('#deleteAllQueues queue id ->', queue.id)
      const response = await this.deleteQueue(queue)
      if (!response) {
        return false
      }
    }
    return true
  }

  async #deleteAllHolidays () {
    // loop over golidays and delete them one by one
    while (this.holidays.length > 0) {
      const holiday = this.holidays.pop()
      console.log('#deleteAllHolidays holiday -> ', holiday.HolidayName)
      const response = await this.deleteHoliday(holiday)
      if (!response) {
        return false
      }
    }
    return true
  }

  async deleteQueue (queue) {
    console.log('deleteQueue queue -> ')
    console.log(queue)
    const response = await deleteQueueFromBU(queue.id)
    if (response) {
      const index = this.#queuesContains(queue)
      if (index > -1) {
        this.queues.splice(index, 1) // remove the item at the index it was found
      }
    }
    return response
  }

  async deleteHoliday (holiday) {
    console.log('deleting holiday ->')
    console.log(holiday)
    const response = await deleteBUHoliday(holiday.id)
    if (response) {
      const index = this.#holidaysContains(holiday)
      if (index > -1) {
        this.holidays.splice(index, 1) // remove the item at the index it was found
      }
    }
    return response
  }
}
