import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from '../Components/LMDataGrid'
import { storePublicHoliday, loadPublicHolidays, getDateString, deletePublicHoliday } from '../APIUtils/BackendHandler'

export default function PublicHolidays () {
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'HolidayName',
      headerName: 'Holiday Name',
      type: 'string',
      width: 350,
      editable: true
    },
    {
      field: 'Date',
      headerName: 'Date',
      type: 'date',
      width: 180,
      editable: true,
      valueFormatter: params => getDateString(params?.value)
    },
    {
      field: 'Closed',
      headerName: 'Closed',
      editable: true,
      type: 'boolean'
    }
  ]

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      HolidayName: 'Enter a holiday name',
      Date: null,
      Closed: true
    }
    return item
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')
    const success = await storePublicHoliday(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting item in main with row id->', id)

    const success = await deletePublicHoliday(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    }

    return success
  }

  useEffect(() => {
    const doLoadHolidays = async () => {
      const holidays = await loadPublicHolidays()
      setRows(holidays)
      setOpenBackdrop(false)
    }
    doLoadHolidays()
  }, [])

  return (
    <div style={{ margin: '10px', marginTop: '20px' }}>
        <CssBaseline/>
        <p>Configure Public Holidays by adding/removing entries and indicating whether the contact center is open or closed on the day.</p>
        <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='HolidayName'
                    createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
                    setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage}
        />
    </div>
  )
}
