import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import LMDataGrid from './LMDataGrid'
// import Switch from '@mui/material/Switch'
import {
  loadAllBUQueues, loadConnectQueues, saveBuQueueConfig, deleteQueueFromBU
} from '../APIUtils/BackendHandler'
import EditAutocomplete from '../Components/EditAutocomplete'

export default function Queues ({ businessUnit }) {
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [unmappedQueues, setUnmappedQueues] = React.useState([''])

  const [buQueues, setBuQueues] = React.useState(undefined)
  const [allBuQueues, setAllBuQueues] = React.useState(undefined)
  const [connectQueues, setConnectQueues] = React.useState([])

  // const MySwitch = (params) => {
  //   const [count, setCount] = React.useState(0)
  //   // console.log('params->')
  //   // console.log(params.params)
  //   if (params?.params?.row?.QueueName === 'BasicQueue') {
  //     // console.log('cellmode->', params?.params?.cellMode)
  //     console.log(params.params)
  //   }
  //   // console.log('emergency for ', params?.row?.QueueName, '=', params?.row?.EmergencyClose)
  //   return (
  //     <Switch
  //         checked={params?.params.row?.EmergencyClose}
  //         onChange={() => handleConfirmChange(params?.params.row)}
  //       />
  //   )
  // }

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'QueueName',
      headerName: 'Queue',
      editable: true,
      type: 'singleSelect',
      valueOptions: unmappedQueues,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={unmappedQueues} />
        )
      },
      flex: 100,
      minWidth: 250
    },
    {
      field: 'EmergencyClose',
      headerName: 'Emergency Close',
      editable: true,
      type: 'boolean',
      width: 200
    }, /*    {
      field: 'EmergencyClose',
      headerName: 'Emergency Close',
      type: 'boolean',
      // editable: true,
      renderCell: (params) => <MySwitch params={params}/>
    } */
    {
      field: 'CloseMessage',
      headerName: 'Close Message',
      editable: true,
      type: 'string',
      flex: 200,
      minWidth: 250
    }
  ]

  // function handleConfirmChange (clickedRow) {
  //   console.log('clickedRow -> ')
  //   console.log(clickedRow)
  // }

  // this useEffect will load all queues associated with Business Units
  useEffect(() => {
    loadBUQueues()
  }, [businessUnit])

  const loadBUQueues = async () => {
    setOpenBackdrop(true)
    const allBUQueues = []
    const selectedBUqueus = []

    if (businessUnit) {
      businessUnit.clearQueues()
      const response = await loadAllBUQueues(businessUnit.id)

      for (let index = 0; index < response.length; index++) {
        const queue = response[index]
        // add to array containing all queues
        allBUQueues.push(queue)
        // add to selected BU array if bu id matches
        if (queue.BUID === businessUnit.id) {
          selectedBUqueus.push(queue)
          businessUnit.addQueue(queue)
        }
      }
    }
    setBuQueues(selectedBUqueus)
    setAllBuQueues(allBUQueues)
    setOpenBackdrop(false)
  }

  // the below useEffect will load all amazon connect queues
  useEffect(() => {
    const loadConnectQueus = async () => {
      const response = await loadConnectQueues()
      setConnectQueues(response)
    }
    loadConnectQueus()
  }, [])

  // this useEffect triggers a calculation of the available queues once the prerequisites have been set
  useEffect(() => {
    // calculated the available (unmapped) queues once both connectQueues and allBuQueuss have been set
    if (connectQueues && allBuQueues) {
      calculateAvailableQueues()
    }
  }, [connectQueues, allBuQueues])

  function calculateAvailableQueues () {
    if ((connectQueues !== undefined) && (allBuQueues !== undefined) && (buQueues !== undefined)) {
      const available = []
      console.log('checking available queues')
      for (let index = 0; index < connectQueues.length; index++) {
        const queue = connectQueues[index]
        let queueAssigned = false
        for (let index2 = 0; index2 < allBuQueues.length; index2++) {
          const buQueue = allBuQueues[index2]
          if (queue.queueName === buQueue.QueueName) {
            queueAssigned = true
            break
          }
        }
        // check if it was assigned and if not add to available queues
        if (!queueAssigned) {
          available.push(queue.queueName)
        }
      }
      // add the queues already associated with this specific bussiness unit to the list of "unmapped" queues
      // if these are not added the queue dropdown will not display in - edit mode - as the current queue name
      // won't be on the list
      for (let index = 0; index < buQueues.length; index++) {
        const queue = buQueues[index]
        available.push(queue.QueueName)
      }

      // const tmp = [...available, ...buQueues]
      // console.log('tmp->')
      // console.log(tmp)
      setUnmappedQueues(available)
    }
  }

  useEffect(() => {
    if (buQueues) {
      console.log('setting bu queues in Queues')
      setRows(buQueues)
    }
  }, [buQueues])

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      QueueName: '',
      EmergencyClose: false,
      CloseMessage: ''
    }
    return item
  }

  useEffect(() => {
    // setOpenBackdrop(false)
  }, [])

  const addBuQueueConfig = async (queueInfo) => {
    // setOpenBackdrop(true)
    const tmp = queueInfo
    // look for the correct queue arn as the user might have selected a new queue
    const queueArn = getConnectQueueArn(queueInfo.QueueName)
    console.log('found queueArn->', queueArn)
    tmp.QueueArn = queueArn

    const success = await saveBuQueueConfig(tmp, businessUnit)
    if (success) {
      // selectedBU.que
    }
    setOpenBackdrop(false)
    return success
  }

  function getConnectQueueArn (queueName) {
    for (let i = 0; i < connectQueues.length; i++) {
      const queueInfo = connectQueues[i]
      if (queueInfo.queueName === queueName) {
        return queueInfo.queueArn
      }
    }
    return undefined
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')
    console.log(updatedRow)

    // check if queue message is set when closed
    if ((updatedRow.EmergencyClose === true) && (updatedRow.CloseMessage.length === 0)) {
      setErrorMessage('Closed queues must have a Close Message set')
      return false
    }

    // first check if this queue is already associated with one of the other entries
    let found = false
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index]
      if (row.id !== updatedRow.id) {
        // this is different row so check that the queue is not the same
        if (row.QueueName === updatedRow.QueueName) {
          found = true
          setErrorMessage('An entry already exists for this queue.')
          break
        }
      }
    }

    if (found) {
      // should not save this entry as an entry with this queue name already exists
      return false
    }

    const success = await addBuQueueConfig(updatedRow)
    if (success) {
      // load the new BU queues which will calculate the unmapped queue
      loadBUQueues()
    }

    // success = await saveBackofficeConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting queue in queues with id->', id)
    const success = await deleteQueueFromBU(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    } else {
      // load the new BU queues which will calculate the unmapped queue
      loadBUQueues()
    }
    return success
  }

  const updatedQueueAllowedValuesBeforeEdit = (id) => {
    console.log('Queues knows must update allowedValues')
    console.log('row id->', id)
    if (id) {
      for (let index = 0; index < rows.length; index++) {
        const queue = rows[index]
        if (queue.id === id) {
          console.log('adding queue name ', queue.QueueName)
          // setUnmappedQueues(...unmappedQueues, queue.queueName)
          return
        }
      }
    }
  }

  return (
        <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='QueueName'
        createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage} updateValueOptionsBeforEditStarts={updatedQueueAllowedValuesBeforeEdit}
/>
  )
}
Queues.propTypes = {
  businessUnit: PropTypes.object
}
