import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from './LMDataGrid'
import { loadBackOfficeDIDs, getAgentUsernameList, getConnectPhoneNumbers, saveBackofficeConfig, deleteBackOfficeConfigEntry } from '../APIUtils/BackendHandler'
import EditAutocomplete from '../Components/EditAutocomplete'

export default function BackOffice () {
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [agents, setAgents] = React.useState([''])
  const [phoneNmbers, setPhoneNumbers] = React.useState([''])

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'Agent',
      headerName: 'User',
      editable: true,
      type: 'singleSelect',
      valueOptions: agents,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={agents} />
        )
      },
      minWidth: 300
    },
    {
      field: 'PhoneNumber',
      headerName: 'Phone number',
      editable: true,
      type: 'singleSelect',
      valueOptions: phoneNmbers,
      renderEditCell: (params) => {
        return (
          <EditAutocomplete params={params} renderOptions={phoneNmbers} />
        )
      },
      minWidth: 180
    },
    {
      field: 'OfferVoicemail',
      headerName: 'Enable Voicemail',
      editable: true,
      type: 'boolean',
      minWidth: 150
    },
    {
      field: 'UseCustomMessage',
      headerName: 'Use Custom Message',
      editable: true,
      type: 'boolean',
      minWidth: 180
    },
    {
      field: 'VoicemailLanguage',
      headerName: 'Voicemail Language',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['', 'English', 'French'],
      minWidth: 180
    },
    {
      field: 'VoicemailMessage',
      headerName: 'Voicemail Message',
      editable: true,
      type: 'string',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'RecordCall',
      headerName: 'Enable call recording',
      editable: true,
      type: 'boolean',
      minWidth: 200
    }
  ]

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      Agent: '',
      PhoneNumber: '',
      OfferVoicemail: false,
      VoicemailMessage: '',
      UseCustomMessage: false,
      VoicemailLanguage: '',
      RecordCall: false
    }
    return item
  }

  const checkDuplicatePhoneNumberEntry = (item) => {
    const entryId = item.id
    const entryPhoneNumber = item.PhoneNumber
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index]
      if (element.id !== entryId) {
        if (entryPhoneNumber === element.PhoneNumber) {
          return true
        }
      }
    }
    return false
  }

  const validateRow = (item) => {
    // check if the agent field has a value
    if (item.Agent === '') {
      setErrorMessage('Please select a user from the list.')
      return false
    }

    // check if the phone number field has a value
    if (item.PhoneNumber === '') {
      setErrorMessage('Please select a phone number from the list.')
      return false
    }

    if (checkDuplicatePhoneNumberEntry(item)) {
      setErrorMessage('The phone number is already associated with another user')
      return false
    }

    if (item.UseCustomMessage === true && item.VoicemailMessage.trim().length === 0) {
      setErrorMessage('A voicemail message must be entered if Use Custom Message is true')
      return false
    }

    if (item.UseCustomMessage === true && item.VoicemailLanguage.trim().length === 0) {
      setErrorMessage('A voicemail language must be selected if Use Custom Message is true')
      return false
    }

    return true
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')

    let success = validateRow(updatedRow)
    if (!success) {
      return false
    }

    success = await saveBackofficeConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting item in main with row id->', id)

    const success = await deleteBackOfficeConfigEntry(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    }

    return success
  }

  useEffect(() => {
    const doLoadEntries = async () => {
      const entries = await loadBackOfficeDIDs()
      setRows(entries)
      setOpenBackdrop(false)
    }
    doLoadEntries()
  }, [])

  useEffect(() => {
    const doLoadAgentList = async () => {
      const entries = await getAgentUsernameList()
      console.log('foound ', entries.length, ' agents')
      setAgents(entries)
    }
    doLoadAgentList()
  }, [])

  useEffect(() => {
    const doLoadPhoneNumbers = async () => {
      const entries = await getConnectPhoneNumbers()
      // console.log('found ', entries.length, ' phone numbers')
      setPhoneNumbers(entries)
    }
    doLoadPhoneNumbers()
  }, [])

  return (
        <div style={{ margin: '10px' }}>
            <CssBaseline/>
            <p>Configured DID numbers for users who require a dedicated number which will route calls directly to their user.</p>
            <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='Agent'
                        createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openBackdrop}
                        setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage}
            />
        </div>
  )
}
