import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import { MenuItem } from '@mui/material'

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#46807C'
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#9ac8c5'
  },
  '&:hover .MuiSwitch-switchBase+.MuiSwitch-switchBase.Mui-checked': {
    color: '#46807C'
  }
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#46807C'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#46807C'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderBottomColor: '#46807C'
    },
    '&:hover fieldset': {
      borderBottomColor: '#46807C'
    },
    '&.Mui-focused fieldset': {
      borderBottomColor: '#46807C'
    }
  }
}))

export const StyledButton = styled(Button)(({ variant }) => ({
  ...(variant === 'contained' && {
    backgroundColor: '#46807C',
    '&:hover': {
      backgroundColor: '#9ac8c5',
      color: '#000000'
    }
  }),
  ...(variant === 'text' && {
    color: '#46807C'
  })
}))

export const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid #46807C',
    padding: '12px 26px 12px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4
      // border: '5px solid #46807C'
    },
    '&:hover': {
      borderRadius: 4,
      borderColor: 'black'
    }
  },
  '&.MuiInputBase-root': {
    borderRadius: 4,
    '&.Mui-focused': {
      border: '1px solid #46807C'
    }
  }
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    color: '#46807C',
    backgroundColor: 'white',

    '& .Mui-focused': {
      color: '#46807C',
      backgroundColor: 'white',
      maxWidth: 'calc(100%)',
      width: 'calc(100%)',
      paddingLeft: '5px',
      paddingRight: '2px'
    },
    '&.MuiInputLabel-shrink': {
      color: '#46807C',
      backgroundColor: 'white',
      maxWidth: 'calc(100%)',
      width: 'calc(100%)',
      paddingLeft: '5px',
      paddingRight: '2px'
    }
  }
}))

export const StyledSelectMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root': {
    color: '#46807',
    '&:hover': {
      backgroundColor: '#9ac8c5',
      color: '#000000',
      '&.Mui-selected': {
        backgroundColor: '#f2f8f7'
      }
    },
    '&.Mui-selected': {
      backgroundColor: '#f2f8f7'
    }
  }
}))
