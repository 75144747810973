import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import LMDataGrid from './LMDataGrid'
// import Switch from '@mui/material/Switch'
import {
  loadBUHolidays, saveBUHoliday, getDateString, deleteBUHoliday
} from '../APIUtils/BackendHandler'

export default function Holidays ({ businessUnit }) {
  const [rows, setRows] = React.useState([])
  const [openHolBackdrop, setOpenHolBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [buHolidays, setBuHolidays] = React.useState(undefined)

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'HolidayName',
      headerName: 'Holiday Name',
      type: 'string',
      width: 200,
      editable: true
    },
    {
      field: 'HolidayDate',
      headerName: 'Date',
      type: 'date',
      width: 180,
      editable: true,
      valueFormatter: params => getDateString(params?.value)
    },
    {
      field: 'Closed',
      headerName: 'Closed',
      editable: true,
      type: 'boolean'
    },
    {
      field: 'CloseMessage',
      headerName: 'Close Message',
      editable: true,
      type: 'string',
      flex: 100,
      minWidth: 250
    }
  ]

  // function handleConfirmChange (clickedRow) {
  //   console.log('clickedRow -> ')
  //   console.log(clickedRow)
  // }

  // this useEffect will load all queues associated with Business Units
  useEffect(() => {
    loadHolidays()
  }, [businessUnit])

  const loadHolidays = async () => {
    console.log('loadHolidays -> opening backdrop')
    const holidays = []

    if (businessUnit) {
      businessUnit.clearHolidays()
      setOpenHolBackdrop(true)
      const response = await loadBUHolidays(businessUnit.id)

      for (let index = 0; index < response.length; index++) {
        const holiday = response[index]
        // add to array containing all queues
        holidays.push(holiday)
        businessUnit.addHoliday(holiday)
      }
    }
    setBuHolidays(holidays)
    setOpenHolBackdrop(false)
  }

  useEffect(() => {
    if (buHolidays) {
      console.log('setting bu holidays in Holidays')
      setRows(buHolidays)
    }
  }, [buHolidays])

  function addNewEntry (id) {
    console.log('creating new entry with id->', id)
    const item = {
      id,
      BUID: businessUnit.id,
      HolidayName: 'Enter a holiday name',
      HolidayDate: undefined,
      Closed: true,
      CloseMessage: ''
    }
    return item
  }

  useEffect(() => {
    // setOpenHolBackdrop(false)
  }, [])

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')
    console.log(updatedRow)

    // check if a date has been selected
    if (updatedRow.HolidayDate === undefined) {
      setErrorMessage('Please select a date.')
      return false
    }

    // check if closed message is set
    console.log('checking if close message set')
    if (updatedRow.CloseMessage.trim().length === 0) {
      console.log('close message NOT set')
      setErrorMessage('Please specify a Close Message.')
      return false
    }

    const success = await saveBUHoliday(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    } else {
      loadHolidays()
    }
    return success
  }

  const deleteRow = async (id) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('deleting holiday with id->', id)
    const success = await deleteBUHoliday(id)
    if (!success) {
      setErrorMessage('Failed to delete the item')
    } else {
      // load the new BU queues which will calculate the unmapped queue
      loadHolidays()
    }
    return success
  }

  return (
        <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='HolidayName'
        createNewItem={addNewEntry} saveRow={saveRow} deleteRow={deleteRow} openBackdrop={openHolBackdrop}
        setOpenBackdrop={setOpenHolBackdrop} errorMessage={errorMessage}
        />
  )
}
Holidays.propTypes = {
  businessUnit: PropTypes.object
}
