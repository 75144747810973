import React, { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import VoicemailContactCenter from '../Components/VoicemailContactCenter'
import VoicemailBackOffice from '../Components/VoicemailBackOffice'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}>
            {value === index && (
              <Box sx={{ p: 0 }}>
                <div>{children}</div>
              </Box>
            )}
        </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const StyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: '#46807C'
  }
})

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function Voicemail () {
  const [value, setValue] = useState(0)

  function handleChange (event, newValue) {
    setValue(newValue)
  }
  const backOfficeTable = window.getConfig.DynamoDBBackOfficeTable
  let showBackOffice = false
  if ((backOfficeTable !== '') && (backOfficeTable !== 'None')) {
    showBackOffice = true
  }

  return (

        <div style={{ margin: '10px' }}>
            <CssBaseline/>
            <Box sx={{ width: '100%', backgroundColor: 'white' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0', backgroundColor: 'white' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                          sx: {
                            backgroundColor: '#46807C'
                          }
                        }}
                        aria-label="secondary tabs example">
                            <StyledTab sx={{ fontSize: '18px' }} label="Contact Center" {...a11yProps(0)} />
                            { showBackOffice &&
                                <StyledTab sx={{ fontSize: '18px' }} label="Back Office" {...a11yProps(1)} />
                            }
                    </Tabs>
                </Box>
                <div >
                    <div>Notes:
                        <ul>
                            <li>
                                Be sure to check that your environment has been properly configured for Email and or Tasks before
                                selecting a delivery channel. Selecting a channel when it is not properly configured will result in lost
                                voicemail messages.
                            </li>
                            {
                                value === 1 &&
                                <li>
                                    Users will require a DID number to be configured on the Back Office page before the
                                    voicemail configuration will have an effect.
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                <TabPanel value={value} index={0}>
                    <VoicemailContactCenter/>
                </TabPanel>
                { showBackOffice &&
                    <TabPanel value={value} index={1}>
                        <VoicemailBackOffice/>
                    </TabPanel>
                }
            </Box>
        </div>
  )
}
